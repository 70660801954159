import { isPlatformServer } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { User } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Device } from '@capacitor/device';
import { GetResult, Preferences } from '@capacitor/preferences';
import {
  ActionPerformed,
  PermissionStatus,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
// import { TranslocoService } from '@ngneat/transloco';
// import { TuiDialogService } from '@taiga-ui/core';
import type { PartialDeep } from 'type-fest';
// import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { formatISO } from 'date-fns';
import { jwtDecode } from 'jwt-decode';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subscription,
  combineLatest,
  from,
  of,
  // zip,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  share,
  skip,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  IDecodedEASIToken,
  IMe,
  IPerson,
  IUser,
  PersonsService,
  UsersService,
} from '@lancelot-frontend/api';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { CobrowseIO } from '@lancelot-frontend/plugins';
import {
  REGISTER_AS_STUDENT_STORAGE_KEY,
  REGISTER_AS_TEACHER_STORAGE_KEY,
  SIGNED_IN_PERSON_PREF_KEY,
  SIGNED_IN_USER_EASI_TOKEN_PREF_KEY,
  SIGNED_IN_USER_PREF_KEY,
} from '../app.constants';
import { AppService } from '../app.service';
import { AppAnalyticsService } from '../app-analytics.service';
import { AuthenticationService } from '../authentication/authentication.service';
// import { DisabledNotificationsWarningDialogComponent } from './components/disabled-notifications-warning-dialog/disabled-notifications-warning-dialog.component';

const parsePreferencesResult = ({ value }: GetResult) => {
  if (value) {
    try {
      return JSON.parse(value);
    } catch {
      return null;
    }
  }

  return value;
};

@Injectable({
  providedIn: 'root',
})
export class SignedInUserService {
  private router = inject(Router);
  private platformId = inject(PLATFORM_ID);
  private app = inject(AppService);
  private auth = inject(AuthenticationService);
  private environmentService = inject(EnvironmentService);
  private analyticsService = inject(AppAnalyticsService);
  private usersService = inject(UsersService);
  private personsService = inject(PersonsService);

  private readonly _refreshCacheTimeStamp$ = new BehaviorSubject<
    number | undefined
  >(undefined);
  private _cachedSignedInUser?: IMe | null;
  private _cachedFetchSignedInUser$: Observable<IMe> | null = null;
  private _cachedSignedInPerson?: IPerson | null;
  private _cachedFetchSignedInPerson$: Observable<IPerson> | null = null;
  private _cachedSignedInUserEASIToken?: null | string;
  private _cachedFetchSignedInUserEASIToken$: Observable<
    null | string | undefined
  > | null = null;

  public readonly signedInUser$: Observable<IMe | null> = EMPTY;
  public readonly signedInPerson$: Observable<IPerson | null> = EMPTY;
  public readonly signedInUserEASIToken$: Observable<
    null | string | undefined
  > = EMPTY;
  public readonly signedInUserLinkToEASILicenseeSpace$: Observable<
    null | string
  > = EMPTY;
  public readonly signedInUserLinkToEASIProSpace$: Observable<null | string> =
    EMPTY;
  public readonly signedInUserLinkToEducationSpace$: Observable<null | string> =
    EMPTY;
  private readonly _signedInUserFCMToken$ = new BehaviorSubject<null | string>(
    null,
  );
  get signedInUserFCMToken$() {
    return this._signedInUserFCMToken$.asObservable();
  }

  validatingEmail = false;

  constructor() {
    if (!isPlatformServer(this.platformId)) {
      const backgroundRefreshObservables: Observable<unknown>[] = [];
      const backgroundRefreshSubscriptions: Subscription[] = [];

      if (window.location.pathname !== '/auth/logout') {
        Promise.all([
          Preferences.get({ key: SIGNED_IN_USER_PREF_KEY }).then((res) => {
            const signedInUser = parsePreferencesResult(res);
            if (signedInUser && !this._cachedSignedInUser) {
              this._cachedSignedInUser = signedInUser;
              this.analyticsService.setUser(signedInUser);
              backgroundRefreshObservables.push(this._fetchSignedInUser());
            }
          }),
          Preferences.get({ key: SIGNED_IN_PERSON_PREF_KEY }).then((res) => {
            const signedInPerson = parsePreferencesResult(res);
            if (signedInPerson && !this._cachedSignedInPerson) {
              this._cachedSignedInPerson = signedInPerson;
              CobrowseIO.setCustomData({
                user_id: signedInPerson.ffbId?.toString() ?? '',
                user_email: signedInPerson.email,
                user_name: `${signedInPerson.firstName ?? ''} ${
                  signedInPerson.lastName ?? ''
                }`,
              });
              backgroundRefreshObservables.push(this._fetchSignedInPerson());
            }
          }),
          Preferences.get({ key: SIGNED_IN_USER_EASI_TOKEN_PREF_KEY }).then(
            (res) => {
              const signedInUserEASIToken = parsePreferencesResult(res);
              if (
                signedInUserEASIToken &&
                this._cachedSignedInUserEASIToken === undefined
              ) {
                // Discard expired EASI token to prevent user to be signed out (except for admin users on browser)
                if (signedInUserEASIToken.token) {
                  try {
                    const { is_admin_user, exp } = jwtDecode<IDecodedEASIToken>(
                      signedInUserEASIToken.token,
                    );
                    const tokenExpired = exp <= Date.now() / 1000;

                    if (
                      !tokenExpired ||
                      (is_admin_user && !this.app.isNativePlatform)
                    ) {
                      this._cachedSignedInUserEASIToken =
                        signedInUserEASIToken.token;

                      if (!(is_admin_user && !this.app.isNativePlatform)) {
                        backgroundRefreshObservables.push(
                          this.signedInUser$.pipe(
                            filter((user): user is IMe => !!user),
                            take(1),
                            switchMap(() => this._fetchSignedInUserEASIToken()),
                          ),
                        );
                      }
                    }
                  } catch {
                    // NOOP
                  }
                } else {
                  this._cachedSignedInUserEASIToken =
                    signedInUserEASIToken.token;
                  backgroundRefreshObservables.push(
                    this.signedInUser$.pipe(
                      filter((user): user is IMe => !!user),
                      take(1),
                      switchMap(() => this._fetchSignedInUserEASIToken()),
                    ),
                  );
                }
              }
            },
          ),
        ]).finally(() => {
          backgroundRefreshObservables.forEach((observable) => {
            backgroundRefreshSubscriptions.push(
              observable.subscribe(() => {
                this._refreshCacheTimeStamp$.next(Date.now());
              }),
            );
          });
          this._refreshCacheTimeStamp$.next(Date.now());
        });
      } else {
        this._refreshCacheTimeStamp$.next(Date.now());
      }

      this.signedInUser$ = this._getSignedInUser();
      this.signedInPerson$ = this._getSignedInPerson();
      this.signedInUserEASIToken$ = this._getSignedInUserEASIToken();

      this.signedInUserLinkToEASILicenseeSpace$ =
        this.signedInUserEASIToken$.pipe(
          map((EASIToken) => {
            if (!EASIToken) {
              return null;
            }

            return (
              this.environmentService.get('easiLicenseeUrl') +
              '/#/autolog/' +
              EASIToken
            );
          }),
        );
      this.signedInUserLinkToEASIProSpace$ = combineLatest([
        this.signedInUser$,
        this.signedInUserEASIToken$,
      ]).pipe(
        map(([signedInUser, EASIToken]) => {
          if (!EASIToken) {
            return null;
          }

          try {
            const { is_admin_user } = jwtDecode<IDecodedEASIToken>(EASIToken);

            if (
              is_admin_user ||
              (['ROLE_ADMIN', 'ROLE_PRO'] as IUser['roles']).some(
                (role) => signedInUser?.roles?.includes(role),
              )
            ) {
              return (
                this.environmentService.get('easiProUrl') +
                '/#/autolog/' +
                EASIToken
              );
            } else {
              return null;
            }
          } catch {
            return null;
          }
        }),
      );
      this.signedInUserLinkToEducationSpace$ = this.signedInUserEASIToken$.pipe(
        map((EASIToken) => {
          if (!EASIToken) {
            return null;
          }

          try {
            const { edulib_space_authorized } =
              jwtDecode<IDecodedEASIToken>(EASIToken);

            if (!edulib_space_authorized) {
              return null;
            }

            return (
              this.environmentService.get('educationSpaceUrl') +
              '/autolog/' +
              EASIToken
            );
          } catch {
            return null;
          }
        }),
      );

      if (this.app.isNativePlatform) {
        this.registerNotifications();
      }

      // Email user on new login if they are admin
      this.auth.firebaseUser$
        .pipe(
          skip(1),
          filter((user): user is User => !!user),
          switchMap(() =>
            this.signedInUserEASIToken$.pipe(
              filter((token): token is null | string => token !== undefined),
              take(1),
            ),
          ),
          filter((EASIToken) => {
            if (EASIToken && this.app.focused) {
              try {
                const { is_admin_user } =
                  jwtDecode<IDecodedEASIToken>(EASIToken);

                return !!is_admin_user;
              } catch {
                return false;
              }
            }
            return false;
          }),
          switchMap(() => from(Device.getInfo()).pipe(take(1))),
          switchMap((deviceInformation) => {
            const data: Parameters<
              typeof this.usersService.sendLoginNotification
            >[0] = { ...deviceInformation, datetime: formatISO(new Date()) };

            if (!this.app.isNativePlatform) {
              const browser = new BrowserDetector(window.navigator.userAgent);
              const browserInformation = browser.getBrowserInfo();
              data.browserName = browserInformation.humanReadableName;
              data.browserVersion = browserInformation.version;
            }

            return this.usersService.sendLoginNotification(data);
          }),
        )
        .subscribe();

      // On new login:
      // - if user has no valid email: send email verification
      // - then, if user came from education space: register user as teacher or student
      // - finally, redirect user to the "email not verified" page if needed
      this.auth.firebaseUser$
        .pipe(
          filter((firebaseUser): firebaseUser is User => !!firebaseUser),
          switchMap((firebaseUser) => {
            if (!firebaseUser.emailVerified && !this.validatingEmail) {
              return from(this.auth.sendEmailVerification(firebaseUser)).pipe(
                catchError(() => of(null)),
                map(() => firebaseUser),
              );
            }
            return of(firebaseUser);
          }),
          switchMap((firebaseUser) => {
            if (
              this.app.getSessionStorageItem(REGISTER_AS_STUDENT_STORAGE_KEY)
            ) {
              return this.personsService.setSignedInPersonAsStudent().pipe(
                tap((person) => {
                  if (firebaseUser.emailVerified) {
                    if (this.cachedSignedInUser) {
                      this.cachedSignedInUser = {
                        ...this.cachedSignedInUser,
                        person,
                      };
                    }
                    this.cachedSignedInPerson = person;
                    this._refreshCacheTimeStamp$.next(Date.now());
                  }
                }),
                catchError(() => of(null)),
                map(() => firebaseUser),
              );
            } else if (
              this.app.getSessionStorageItem(REGISTER_AS_TEACHER_STORAGE_KEY)
            ) {
              return this.personsService.setSignedInPersonAsTeacher().pipe(
                tap((person) => {
                  if (firebaseUser.emailVerified) {
                    if (this.cachedSignedInUser) {
                      this.cachedSignedInUser = {
                        ...this.cachedSignedInUser,
                        person,
                      };
                    }
                    this.cachedSignedInPerson = person;
                    this._refreshCacheTimeStamp$.next(Date.now());
                  }
                }),
                catchError(() => of(null)),
                map(() => firebaseUser),
              );
            }
            return of(firebaseUser);
          }),
        )
        .subscribe((firebaseUser) => {
          if (!firebaseUser.emailVerified && !this.validatingEmail) {
            this.router.navigate(['auth', 'email-not-verified']);
          } else if (
            this.app.getSessionStorageItem(REGISTER_AS_TEACHER_STORAGE_KEY)
          ) {
            this.router.navigate(['user', 'education']);
          }
          this.app.setSessionStorageItem(
            REGISTER_AS_STUDENT_STORAGE_KEY,
            false,
          );
          this.app.setSessionStorageItem(
            REGISTER_AS_TEACHER_STORAGE_KEY,
            false,
          );
        });

      // Redirect teachers to user education page as soon as they are valid
      this.signedInPerson$
        .pipe(
          filter((person): person is IPerson => !!person),
          distinctUntilChanged((a, b) => a.id === b.id),
          pairwise(),
          filter(
            ([previousPerson, currentPerson]) =>
              !!(
                !previousPerson.valid &&
                currentPerson.valid &&
                currentPerson.teacher
              ),
          ),
          take(1),
        )
        .subscribe(() => {
          this.router.navigate(['user', 'education']);
        });

      // register device as soon as we have signed user and FCM Token
      combineLatest([this._signedInUserFCMToken$, this.signedInUser$])
        .pipe(
          filter((value): value is [string, IMe] => !!(value[0] && value[1])),
          take(1),
        )
        .subscribe(([token]) => {
          this.registerDevice(token);
        });

      // Always reset EASI Token on new Firebase token
      this.auth.firebaseIdToken$.pipe(skip(1)).subscribe(() => {
        this.resetSignedInUserEASIToken();
      });

      // Reset EASI Token on login as
      this.app.loginAs$.pipe(skip(1)).subscribe(() => {
        this.resetSignedInUserEASIToken();
      });

      // Clear preferences on logout
      this.auth.firebaseUser$
        .pipe(filter((firebaseUser) => !firebaseUser))
        .subscribe(() => {
          backgroundRefreshSubscriptions.forEach((subscription) => {
            subscription.unsubscribe();
          });
          Preferences.clear();
        });
    }
  }

  set cachedSignedInUser(user) {
    if (
      ((!this._cachedSignedInUser || !user) &&
        this._cachedSignedInUser !== user) ||
      this._cachedSignedInUser?.id !== user?.id ||
      this._cachedSignedInUser?.firebaseUid !== user?.firebaseUid ||
      this._cachedSignedInUser?.cmsToken !== user?.cmsToken
    ) {
      this.analyticsService.setUser(user);
      this._cachedSignedInUser = user;
      Preferences.set({
        key: SIGNED_IN_USER_PREF_KEY,
        value: JSON.stringify(user),
      });
    }
  }

  get cachedSignedInUser() {
    return this._cachedSignedInUser;
  }

  set cachedSignedInPerson(person) {
    if (
      !this.cachedSignedInPerson ||
      this.cachedSignedInPerson.ffbId !== person?.ffbId ||
      this.cachedSignedInPerson?.email !== person?.email ||
      this.cachedSignedInPerson?.firstName !== person?.firstName ||
      this.cachedSignedInPerson?.lastName !== person?.lastName
    ) {
      // set CobrowseIO custom data
      CobrowseIO.setCustomData({
        user_id: person?.ffbId?.toString() ?? '',
        user_email: person?.email ?? '',
        user_name: `${person?.firstName ?? ''} ${person?.lastName ?? ''}`,
      });
    }

    this._cachedSignedInPerson = person;
    Preferences.set({
      key: SIGNED_IN_PERSON_PREF_KEY,
      value: JSON.stringify(person),
    });
    if (person && this.cachedSignedInUser) {
      this.cachedSignedInUser = {
        ...this.cachedSignedInUser,
        person,
      };
    }
  }

  get cachedSignedInPerson() {
    return this._cachedSignedInPerson;
  }

  set cachedSignedInUserEASIToken(token) {
    this._cachedSignedInUserEASIToken = token;
    Preferences.set({
      key: SIGNED_IN_USER_EASI_TOKEN_PREF_KEY,
      value: JSON.stringify({ token: token }),
    });
  }

  get cachedSignedInUserEASIToken() {
    return this._cachedSignedInUserEASIToken;
  }

  private _fetchSignedInUser() {
    return this.usersService.getSignedInUser().pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status >= 500) {
          this.app.apiDown = true;
        }
        throw error;
      }),
      tap((user) => {
        if (!user.person.emailVerified) {
          // If we try to fetch the user, that means that its email is verified on firebase side.
          // In that case, we need to validate it on our side.
          this.validateEmail().subscribe();
          user.person.emailVerified = true;
        }
        this.cachedSignedInUser = user;
      }),
    );
  }

  private _getSignedInUser() {
    return this._refreshCacheTimeStamp$.pipe(
      filter((timestamp) => !!timestamp),
      switchMap(() =>
        this.auth.firebaseUser$.pipe(
          switchMap((firebaseUser) => {
            if (!firebaseUser || !firebaseUser.emailVerified) {
              this.resetSignedInUser();
              return of(null);
            }

            if (
              this.cachedSignedInUser &&
              firebaseUser?.uid !== this.cachedSignedInUser?.firebaseUid &&
              this.cachedSignedInUser?.firebaseUid !== this.app.loginAs
            ) {
              this.resetSignedInUser();
            }

            if (this.cachedSignedInUser) {
              return of(this.cachedSignedInUser);
            }

            if (!this._cachedFetchSignedInUser$) {
              this._cachedFetchSignedInUser$ =
                this._fetchSignedInUser().pipe(share());
            }

            return this._cachedFetchSignedInUser$;
          }),
          catchError((error) => {
            if (!this.app.apiDown) {
              this.auth.signOut();
            } else {
              this.resetSignedInUser();
            }
            throw error;
          }),
        ),
      ),
    );
  }

  private _fetchSignedInPerson() {
    return this.personsService.getSignedInPerson().pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status >= 500) {
          this.app.apiDown = true;
        }
        throw error;
      }),
      tap((person) => {
        this.cachedSignedInPerson = person;
      }),
    );
  }

  private _getSignedInPerson() {
    return this._refreshCacheTimeStamp$.pipe(
      filter((timestamp) => !!timestamp),
      switchMap(() =>
        this.signedInUser$.pipe(
          switchMap((user) => {
            if (!user) {
              this.resetSignedInPerson();
              return of(null);
            }

            if (this.cachedSignedInPerson) {
              return of(this.cachedSignedInPerson);
            }

            if (!this._cachedFetchSignedInPerson$) {
              this._cachedFetchSignedInPerson$ =
                this._fetchSignedInPerson().pipe(share());
            }

            return this._cachedFetchSignedInPerson$;
          }),
        ),
      ),
    );
  }

  private _fetchSignedInUserEASIToken() {
    return this.usersService.getSingedInUserEASIToken().pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status >= 500) {
          this.app.apiDown = true;
        }
        return of(undefined);
      }),
      tap((token) => {
        this.cachedSignedInUserEASIToken = token;
      }),
    );
  }

  private _getSignedInUserEASIToken() {
    return this.signedInUser$.pipe(
      switchMap((user) => {
        if (!user) {
          this.resetSignedInUserEASIToken();
          return of(undefined);
        }

        if (this.cachedSignedInUserEASIToken !== undefined) {
          return of(this.cachedSignedInUserEASIToken);
        }

        if (!this._cachedFetchSignedInUserEASIToken$) {
          this._cachedFetchSignedInUserEASIToken$ =
            this._fetchSignedInUserEASIToken().pipe(share());
        }

        return this._cachedFetchSignedInUserEASIToken$;
      }),
    );
  }

  resetSignedInUser() {
    this.cachedSignedInUser = null;
    this._cachedFetchSignedInUser$ = null;
    this.resetSignedInPerson();
  }

  resetSignedInPerson() {
    this.cachedSignedInPerson = null;
    this._cachedFetchSignedInPerson$ = null;
  }

  resetSignedInUserEASIToken() {
    this.cachedSignedInUserEASIToken = undefined;
    this._cachedFetchSignedInUserEASIToken$ = null;
  }

  private async registerNotifications() {
    let permStatus: PermissionStatus =
      await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive === 'granted') {
      PushNotifications.register();
      this.addNotificationsListeners();
    }
    // else {
    //   zip(
    //     from(
    //       Preferences.get({
    //         key: DISABLED_NOTIFICATIONS_WARNING_PREF_KEY,
    //       }),
    //     ).pipe(catchError(() => of({ value: null }))),
    //     this.app.scopeLoaded('notifications'),
    //   )
    //     .pipe(
    //       take(1),
    //       map(([res]) => parsePreferencesResult(res)),
    //       filter((dialogDiscarded) => !dialogDiscarded),
    //       switchMap(() =>
    //         this.dialogService.open<boolean>(
    //           new PolymorpheusComponent(
    //             DisabledNotificationsWarningDialogComponent,
    //             this.injector,
    //           ),
    //           {
    //             label: this.translocoService.translate(
    //               'notifications.notificationsDisabled.dialogTitle',
    //             ),
    //           },
    //         ),
    //       ),
    //     )
    //     .subscribe();
    // }
  }

  private async addNotificationsListeners() {
    PushNotifications.addListener('registration', (token: Token) => {
      this._signedInUserFCMToken$.next(token.value);
    });

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      ({ actionId, notification }: ActionPerformed) => {
        if (actionId === 'tap' && notification?.data?.url) {
          this.router.navigateByUrl(notification?.data?.url);
        }
      },
    );
  }

  private async registerDevice(FCMToken: string) {
    const { identifier: uuid } = await Device.getId();
    const { name, model, platform, operatingSystem, osVersion } =
      await Device.getInfo();

    this.usersService
      .registerSignedInUserDevice({
        FCMToken,
        uuid,
        name,
        model,
        platform,
        operatingSystem,
        osVersion,
      })
      .subscribe();
  }

  validateEmail() {
    return this.usersService.validateSignedInUserEmail().pipe(
      tap((userWithValidatedEmail) => {
        this.cachedSignedInUser = userWithValidatedEmail;
        if (this.cachedSignedInPerson) {
          this.cachedSignedInPerson = {
            ...this.cachedSignedInPerson,
            ...userWithValidatedEmail.person,
          };
        }
        this._refreshCacheTimeStamp$.next(Date.now());
      }),
    );
  }

  validateFirebaseEmail(token: string) {
    this.validatingEmail = true;
    return this.usersService.validateFirebaseEmail(token).pipe(
      tap(() => {
        this.validatingEmail = false;
      }),
      catchError((error) => {
        this.validatingEmail = false;
        throw error;
      }),
    );
  }

  updateProfile(data: PartialDeep<IPerson>) {
    return this.personsService.updateSignedInPerson(data).pipe(
      tap((person) => {
        if (this.cachedSignedInUser) {
          this.cachedSignedInUser = {
            ...this.cachedSignedInUser,
            person,
          };
        }
        this.cachedSignedInPerson = person;
        this._refreshCacheTimeStamp$.next(Date.now());
      }),
    );
  }

  updateEmail(newEmail: IPerson['email']) {
    return this.personsService.updateSignedInPersonEmail({ newEmail }).pipe(
      tap(() => {
        this.auth.signOut();
      }),
    );
  }

  updateAvatar(file: Blob) {
    return this.personsService.updateSignedInPersonAvatar(file).pipe(
      tap((person) => {
        if (this.cachedSignedInUser) {
          this.cachedSignedInUser = {
            ...this.cachedSignedInUser,
            person,
          };
        }
        this.cachedSignedInPerson = person;
        this._refreshCacheTimeStamp$.next(Date.now());
      }),
    );
  }

  deleteAvatar() {
    return this.personsService.deleteSignedInPersonAvatar().pipe(
      tap(() => {
        if (this.cachedSignedInPerson) {
          this.cachedSignedInPerson = {
            ...this.cachedSignedInPerson,
            avatarUrl: undefined,
          };
        }
        this._refreshCacheTimeStamp$.next(Date.now());
      }),
    );
  }

  deleteAccount() {
    return this.usersService
      .deleteSignedInUser()
      .pipe(tap(() => this.auth.signOut()));
  }
}
